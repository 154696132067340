.delay {
  background: white;
  border-radius: 4px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  justify-content: start;
  // align-items: center;

  &-title {
    cursor: pointer;
    &:hover {
      color: $defaultcolor;
    }
  }

  .form {
    margin-bottom: 10px;
    input,
    select {
      height: 40px;
      border-radius: 4px;
      background-color: $light_grey;
      border: 1px solid $grey;
      padding: 0px 15px;
      width: 100%;
      font-size: $extrathin;
      // height: fit-content;
      &:focus {
        outline-offset: 0px;
        outline: none;
        padding: 0px 15px;
        border: 1px solid blue;
      }
    }
    textarea {
      // height:40px;
      border-radius: 4px;
      background-color: $light_grey;
      border: 1px solid $grey;
      padding: 20px 20px;
      width: 100%;
      font-size: $extrathin;
      // height: fit-content;
      &:focus {
        outline-offset: 0px;
        outline: none;
        padding: 20px 20px;
        border: 1px solid blue;
      }
    }
    .showerror {
      border: 1px solid red;
      background: #ff434305;
      &:focus {
        border: 1px solid red;
        outline-offset: 0px;
        outline: none;
      }
    }
  }

  &-list {
    margin-top: 15;
    max-height: 500px;
    overflow-y: auto;

    &-row {
      display: grid;
      row-gap: 5px;
      padding: 10px;

      &-cursor-hover {
        cursor: pointer;
        &:hover {
          color: #298d27;
        }
      }

      &-content {
        display: grid;
        row-gap: 5px;

        &-status {
          background: #fcff7c;
          padding: 2px 8px;
          border-radius: 4px;
        }
        &-description {
          margin-left: 10px;
          background: #ffabab;
          padding: 2px 8px;
          border-radius: 4px;
        }
        &-edit {
          padding: 10px 15px;
          cursor: pointer;
          display: inline-block;
          background: #298d27;
          color: white;
          box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 1);
          &:hover {
            box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 1);
            transition: box-shadow 0.5s ease-in ease-out;
          }
        }
      }

      &-create {
        padding: 10px 15px;
        cursor: pointer;
        display: inline-block;
        background: #4971ff;
        color: white;
        box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 1);
        &:hover {
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 1);
          transition: box-shadow 0.5s ease-in ease-out;
        }
      }
    }
  }
}
