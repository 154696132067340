@import "./addgxSite";
.gxsite {

    .form{
        // display: flex;
        // flex-direction: row;

        input,select{

            height:40px;
            border-radius:4px;
            background-color: $light_grey;
            border: 1px solid $grey;
            padding: 0px 15px;
            width: 250px;
            font-size: $extrathin;
            margin-right: 10px;
            // height: fit-content;
            
            &:focus{
                outline-offset: 0px ;
                outline: none ;
                padding: 0px 15px;  
                border: 1px solid blue;
                }
        }
    }
}
