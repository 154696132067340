.compwrapper-container {
  // padding-left: 28vw;
  padding-right: 20px;

  // @include responsive-for(xlg) {
  //   padding-left: 28vw;
  // }

  // @include responsive-for(lg) {
  //   padding-left: 4vw;
  //   padding-right: 4vw;
  // }

  // .containerbox {
  //   padding-right: 20px;
  //   padding-bottom: 50px;
  // }
}
