.addticket {
  width: 80%;
  @include responsive-for(lg) {
    width: 100%;
  }
  &-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1.5em;
    padding: 0 40px;

    &-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      @include responsive-for(md) {
        grid-template-columns: 1fr;
      }
    }
    label {
      font-family: $avertaSemiBold;
    }
    .date {
      display: flex;
      flex-direction: column;
    }
    .form {
      input,
      select {
        height: 40px;
        border-radius: 4px;
        background-color: $light_grey;
        border: 1px solid $grey;
        padding: 20px 20px;
        width: 100%;
        font-size: $extrathin;
        height: fit-content;
        &:focus {
          outline-offset: 0px;
          outline: none;
          padding: 20px 20px;
          border: 1px solid blue;
        }
      }
      textarea {
        // height:40px;
        border-radius: 4px;
        background-color: $light_grey;
        border: 1px solid $grey;
        padding: 20px 20px;
        width: 100%;
        font-size: $extrathin;
        // height: fit-content;
        &:focus {
          outline-offset: 0px;
          outline: none;
          padding: 20px 20px;
          border: 1px solid blue;
        }
      }
      .showerror {
        border: 1px solid red;
        background: #ff434305;
        &:focus {
          border: 1px solid red;
          outline-offset: 0px;
          outline: none;
        }
      }
    }

    &-button {
      height: 40px;
      border-radius: 4px;
      background-color: $teal;
      border: none;
      padding: 20px 0px;
      width: 100%;
      font-size: $thin;
      font-family: $avertaSemiBold;
      height: fit-content;
      cursor: pointer;
      &:active {
        border: none;
      }
    }
  }

  &-comment {
    margin-top: 30px;
    padding: 0 40px;
    font-family: $avertaSemiBold;

    label {
      font-family: $avertaSemiBold;
    }
    .form {
      textarea {
        border-radius: 4px;
        background-color: $light_grey;
        border: 1px solid $grey;
        padding: 20px 20px;
        width: 100%;
        font-size: $extrathin;
        &:focus {
          outline-offset: 0px;
          outline: none;
          padding: 20px 20px;
          border: 1px solid blue;
        }
      }
      .showerror {
        border: 1px solid red;
        background: #ff434305;
        &:focus {
          border: 1px solid red;
          outline-offset: 0px;
          outline: none;
        }
      }
    }

    &-show {
      margin-bottom: 40px;

      &-list {
        list-style: none;
        &-comment {
          display: flex;
          justify-content: space-between;

          margin-top: 5px;
          padding: 10px;
          background: white;
          border-bottom: 1px solid $defaultborder;
          .left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          .right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
          &-msg {
            padding: 10px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
          }
          &-bottom {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 4px;
            &-date {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
