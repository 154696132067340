// BUTTON STYLES

.button {
    outline: none;
    border: none;
    padding: 7px 40px;
    border-radius: 60px;
    font-size: $thin;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.16);
    
    &.default {
      background: white;
      color: blue;
      border: 2px solid blue;
      transition: background .4s, border-color .8s;
      
      &:hover {
        background: blue;
        color: white;
      }
    
      &:active {
        background: darken(blue, 10%);
      }
  
      
    }
  
    &.default-invert {
      background: blue;
      color: white;
      border: 2px solid white;
      transition: background .4s, border-color .8s;
      
      &:hover {
        background: white;
        color: blue;
      }
    
      &:active {
        background: darken(white, 10%);
        border-color: darken(white, 10%);
      }
    }
  
    &.inverted {
      background: blue;
      color: white;
      border: 2px solid blue;
      transition: background .4s, border-color .8s;
      
      &:hover {
        background: darken(blue, 4%);
        border-color: darken(blue, 4%);
      }
    
      &:active {
        background: darken(blue, 10%);
        border-color: darken(blue, 10%);
      }
    }
  
    &.floating {
      background: $white;
      color: black;
      box-shadow: 0px 5px 40px rgba(0,0,0,0.16);
      padding: 7px 20px;
      cursor: pointer;
      transition: background .4s, border-color .8s;
      border: 3px solid white;
  
      &:hover {
        background: darken($white, 4%);
        border-color: darken($white, 4%);
      }
    
      &:active {
        background: darken($white, 10%);
        border-color: darken($white, 10%);
      }
  
    }
  
    
  }
  
  