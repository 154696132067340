// SIDE NAVIGATION STYLES
.sidenav-container {
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   bottom: 0;
  //   width: 250px;
  //   background: $white;
  //   box-shadow: 4px 0px 19px rgba(0,0,0,0.05);
  //   overflow-y: auto;
  //   transform: translateX(0%);
  // transition: transform 0.5s ease-out;

  //   @media all and (max-width: 667px) {
  //     box-shadow: none;
  //     transform: translateX(-100%);
  //     transition: transform 0.5s ease-out;
  // }

  // @include responsive-for(md) {
  //   box-shadow: none;
  //   transform: translateX(-100%);
  //   transition: transform 0.5s ease-out;
  //   }
  //     @include scrollbar(10px, $grey, $light_grey, darken($grey, 10%));

  .sidenav {
    z-index: 100;
    &-nav {
      list-style: none;

      &-title {
        a {
          // cursor: pointer;
          text-decoration: none;
          display: grid;
          grid-template-columns: 1fr 2fr;
          column-gap: 10px;
          align-items: center;
          color: $black;
          padding: 20px 35px;
          font-family: $avertaBold;
          font-size: larger;

          .icon {
            width: 60px;

            img {
              width: 100%;
            }
          }
        }
      }
      &-item {
        a {
          text-decoration: none;
          display: block;
          color: $black;
          padding: 20px 35px;
          font-family: $avertaSemiBold;
          position: relative;
          user-select: none;
          transition: background 0.3s, color 0.3s;
          display: flex;
          flex-direction: row;
          align-items: center;
          .icon {
            position: relative;
            top: 2px;
            padding-right: 10px;
          }

          &:hover {
            color: darken($defaultcolor, 30%);
            background: lighten($defaultcolor, 32%);
          }

          &:active {
            color: darken($defaultcolor, 10%);
            background: lighten($defaultcolor, 45%);
          }

          &.sidenav-active {
            color: $white;
            background: lighten($defaultcolor, 5%);

            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 8px;
              background: darken($defaultcolor, 20%);
              @include animation("animateleft .5s");

              @include keyframes("animateleft") {
                from {
                  transform: translateX(-20px);
                }
                to {
                  transform: translateX(0);
                }
              }
            }
          }
        }
      }
    }
  }
}

.sidenavigation-mobile-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: none;

  @include responsive-for(lg) {
    display: block;
  }

  .sidenavigation-mobile {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 80vw;
    background: $white;
    border-right: 1px solid $grey;
    padding: 0px 20px;
    box-shadow: 6px 0px 46px rgba(0, 0, 0, 0.08);

    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background-color: $light_grey;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $grey;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: darken($grey, 5%);
    }

    .close-sidenav {
      position: absolute;
      right: 20px;
      top: 45px;
      font-size: 30px;
      cursor: pointer;
    }
  }
}

.sidenavigation-web {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  // width: 200;
  background: $white;
  border-right: 1px solid $grey;
  padding: 0px 20px;
  // display: block;
  overflow-y: auto;
  z-index: 100;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: $light_grey;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $grey;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: darken($grey, 5%);
  }

  // @include responsive-for(xlg) {
  //   width: 26vw;
  // }

  // @include responsive-for(lg) {
  //   display: none;
  // }
}

// SIDE NAV ANIMATION
.sidenavigation-node {
  &-enter {
    opacity: 0;
    transform: translateX(-100%);

    &-active {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 200ms, transform 200ms linear;
    }
  }

  &-exit {
    opacity: 1;
    transform: translateX(0);

    &-active {
      opacity: 0;
      transform: translateX(-100%);
      transition: opacity 200ms, transform 200ms linear;
    }
  }
}
