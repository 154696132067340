

.notification-container{
    .notification{
        background: white;
        border-radius: 4px; 
        box-shadow: 0px 3px 10px rgba(0,0,0,0.12);
        padding:20px;
        
        .header{
            display: flex;
            flex-direction: row;
            /* place-content: center space-between; */
            align-items: flex-end;
            margin-bottom: 10px;
        }
        .card{
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-bottom: 1px solid $defaultborder;
            // background-color: red;
            transition: background-color 0.2s;            
            &:hover{
                cursor: pointer;
                background-color: lighten($grey, 10%);
                // transition: background-color 0.5s;
            }
            &-button{
                button{

                    cursor: pointer;
                }
            }
        }
    }
}