@import "./addgxmachine.scss";

.gxmachine {
  &-select {
    .searchform {
      // margin-bottom: 15px;
      display: flex;
      flex-direction: row;

      input,
      select {
        // height: 40px;
        border-radius: 4px;
        background-color: $light_grey;
        border: 1px solid $grey;
        padding: 0px 15px;
        width: 300px;
        // margin-left: 10px;
        font-size: $extrathin;
        // height: fit-content;
        &:focus {
          outline-offset: 0px;
          outline: none;
          // padding: 0px 20px;
          border: 1px solid blue;
        }
      }
    }
  }
}
.MTableHeader-header-6 {
  z-index: 0;
}
// MuiTableCell-root
.MuiTableCell-head {
  z-index: 0 !important;
}
