.showdatacard {
  background: white;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.4s;
  &:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
    transition: box-shadow 0.4s;
  }
  &-title {
  }

  &-data {
    font-size: 3rem;
    margin-top: 20px;
  }
}

.showlistcard {
  background: white;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  justify-content: flex-start;
  //   align-items: center;
  transition: box-shadow 0.4s;

  &:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
    transition: box-shadow 0.4s;
  }
  &-title {
  }

  &-data {
    // font-size: 3rem;
    margin-top: 20px;
  }
}

.showTable {
  height: 500px;
  overflow: auto;
  table {
    border-collapse: collapse;
    width: 100%;
    thead {
      position: sticky;
      top: 0;
      background-color: rgb(196, 196, 196);
      th {
        padding: 16px 8px;
        text-align: left;
      }
      // border-bottom: 1px solid #ddd;
    }
  }

  .click {
    cursor: pointer;
  }
  td {
    padding: 16px 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  // tr:hover {
  //   background-color: #d6eeee;
  // }
}
