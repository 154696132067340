.gxdetail-container {
  .gxdetail {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;

    %card {
      width: 100%;
      padding: 30px;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
    }
    &-map {
      z-index: 0;
      .leaflet-container {
        width: 100%;
        height: 50vh;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
        position: relative;
      }
    }

    &-graph {
      @extend %card;
      display: grid;
      row-gap: 50px;
      &-loading {
        width: 100%;
        height: 400px;
        background: $light_grey;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-site {
      @extend %card;
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 10px;

      &-name {
        font-family: $avertaSemiBold;
        font-size: large;
      }

      &-contact {
        display: grid;
        grid-template-columns: 2fr 1fr;

        &-name {
          font-size: larger;
        }

        &-number {
          font-size: larger;
        }
      }
    }

    &-machines {
      @extend %card;
      display: grid;
      grid-template-columns: 1fr;

      .machines {
        &-list {
          display: grid;
          grid-template-columns: 2fr 5fr;
          .gxdetail-machines-code {
            // display: inline-flex;
            // align-items: center;
            padding: 0px 10px;
            &-name {
              display: inline-flex;
              align-items: center;
            }
          }
          .serials {
            .gxdetail-machines-item {
              display: grid;
              grid-template-columns: 1fr;
              // grid-template-columns: 2fr 5fr;
              padding: 15px 0px;
              border-bottom: 1px solid $defaultborder;

              &:last-child {
                border-bottom: none;
              }
              &-serial {
                display: inline-flex;
                background-color: $teal;
                align-items: center;
                color: white;
                padding: 10px;
              }

              &-module {
                display: flex;
                flex-direction: column;
                // display: grid;
                // grid-template-columns: 1fr 1fr 1fr 1fr;
                // row-gap: 15px;

                &-code {
                  display: inline-flex;
                  background-color: $teal;
                  align-items: center;
                  color: white;
                  padding: 10px;
                }
                &-each {
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr 1fr;
                  row-gap: 15px;
                }
              }
            }
          }
        }
      }
    }

    &-data {
      @extend %card;

      &-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        row-gap: 20px;

        &-item {
          list-style: none;
          justify-items: center;

          &-title {
            font-size: small;
            font-family: $avertaLight;
            color: $defaulttextcolor;
            display: flex;
            justify-content: center;
          }

          &-data {
            margin-top: 5px;
            font-size: $thin;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}

.graphcontainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid $grey;
  border-radius: 4px;
  &-graph {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
  }
}
