.userpage {
  .form {
    display: flex;
    flex-direction: row;
    gap: 10px;
    input,
    select {
      height: 40px;
      border-radius: 4px;
      background-color: $light_grey;
      border: 1px solid $grey;
      padding: 10px 20px;
      // width: 100%;
      font-size: $extrathin;
      height: fit-content;

      &:focus {
        outline-offset: 0px;
        outline: none;
        padding: 10px 20px;
        border: 1px solid blue;
      }
    }
  }
}
.form {
  // margin-top: 20px;
  label {
    font-family: $avertaSemiBold;
  }
  .passowrd-container {
    position: relative;
    input {
      width: 100%;
    }
    .passowrd-eye {
      position: absolute;
      width: 30px;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
    }
  }
  input,
  select {
    // height: 40px;
    border-radius: 4px;
    background-color: $light_grey;
    border: 1px solid $grey;
    padding: 20px 20px;
    width: 300px;
    font-size: $extrathin;
    // height: fit-content;
    &:focus {
      outline-offset: 0px;
      outline: none;
      // padding: 20px 20px;
      border: 1px solid blue;
    }
  }
  .showerror {
    border: 1px solid red;
    background: #ff434305;
    &:focus {
      border: 1px solid red;
      outline-offset: 0px;
      outline: none;
    }
  }
}
.resetPassword-container {
  position: relative;
  .form {
    margin-top: 20px;
    label {
      font-family: $avertaSemiBold;
    }
    .passowrd-container {
      position: relative;
      input {
        width: 100%;
      }
      .passowrd-eye {
        position: absolute;
        width: 30px;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
      }
    }
    input,
    select {
      // height: 40px;
      border-radius: 4px;
      background-color: $light_grey;
      border: 1px solid $grey;
      padding: 20px 20px;
      width: 300px;
      font-size: $extrathin;
      // height: fit-content;
      &:focus {
        outline-offset: 0px;
        outline: none;
        // padding: 20px 20px;
        border: 1px solid blue;
      }
    }
    .showerror {
      border: 1px solid red;
      background: #ff434305;
      &:focus {
        border: 1px solid red;
        outline-offset: 0px;
        outline: none;
      }
    }
  }
}
