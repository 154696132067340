@import "./addTicketPage";
.ticket {
  .filter {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: flex-end;
    .formWrapper {
      &.spacing {
        width: 250px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }
      .form {
        // min-width: 250px;
        // input,
        // select {
        //   height: 40px;
        //   border-radius: 4px;
        //   background-color: $light_grey;
        //   border: 1px solid $grey;
        //   padding: 0px 15px;
        //   width: 250px;
        //   font-size: $extrathin;
        //   margin-right: 10px;
        //   // height: fit-content;

        //   &:focus {
        //     outline-offset: 0px;
        //     outline: none;
        //     padding: 0px 15px;
        //     border: 1px solid blue;
        //   }
        // }
        input,
        select {
          // height: 40px;
          border-radius: 4px;
          background-color: $light_grey;
          border: 1px solid $grey;
          padding: 0px 15px;
          width: 250px;
          // margin-left: 10px;
          font-size: $extrathin;
          // height: fit-content;
          &:focus {
            outline-offset: 0px;
            outline: none;
            // padding: 0px 20px;
            border: 1px solid blue;
          }
        }

        .react-datepicker__input-container {
          input {
            height: 40px;
          }
        }
      }
    }
  }
}
