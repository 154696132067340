.MuiTable-root tbody tr td:nth-child(3) {
  font-weight: 800 !important;
}
.MuiTab-root:hover {
  background-color: desaturate($color: lighten($teal, 53%), $amount: 50%);
}

.MuiTab-textColorPrimary.Mui-selected {
  background-color: #3f51b5;
  color: white !important;
  font-family: $avertaSemiBold;
}
.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgba(80, 0, 0, 0.2) !important;
}

.result {
  .utilsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 30px;
    .MuiFormControl-marginNormal {
      margin-bottom: 8px;
      margin-left: 10px;
      margin-right: 10px;
      width: 150px;
    }
    .MuiFormControl-marginNormal {
      margin-top: 0px;
      margin-bottom: 8px;
    }

    .formWrapper {
      margin: 0px 20px;
      width: 100%;
    }
    .spacing {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .form {
    // margin-bottom: 15px;
    label {
      font-family: $avertaSemiBold;
    }

    input,
    select {
      // height: 40px;
      border-radius: 4px;
      background-color: $light_grey;
      border: 1px solid $grey;
      padding: 0px 15px;
      width: 100%;
      font-size: $extrathin;
      // height: fit-content;
      &:focus {
        outline-offset: 0px;
        outline: none;
        // padding: 0px 20px;
        border: 1px solid blue;
      }
    }

    .showerror {
      border: 1px solid red;
      background: #ff434305;
      &:focus {
        border: 1px solid red;
        outline-offset: 0px;
        outline: none;
      }
    }
  }
  .react-datepicker__input-container {
    input {
      height: 40px;
    }
  }

  .tooltip {
    position: relative;
    // display: inline-block;
    cursor: pointer;
  }

  .tooltip .tooltiptext {
    top: -5px;
    right: 105%;
    width: 250px;
    visibility: hidden;
    background-color: #fff;
    color: black;
    text-align: center;
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
}
.resultPage-container {
  .resultPage {
    .form-container {
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 30px;

      .formWrapper {
        margin: 0px 20px;
        min-width: 200px;
        max-width: 300px;
      }
      .spacing {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
