
.dashboarddetail-container {
  // padding-top: 80px;
  padding-left: 260px;

  .dashboarddetail {
    padding: 20px;
  }
}

