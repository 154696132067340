@import "./dashboardDetailPage";
@import "./showDataBox";
@import "./nepalmap";

// .dashboard-container {
//   // padding-top: 80px;
//   padding-left: 260px;
%card {
  width: 100%;
  padding: 30px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
}
.dashboard {
  // padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &-graphs {
    @extend %card;
    margin-top: 20px;

    &-piechart {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      row-gap: 50px;

      &-each {
        &-loading {
          width: 400px;
          height: 400px;
          background: $light_grey;
          margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    &-linechart-loading {
      width: 100%;
      height: 400px;
      background: $light_grey;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-cards {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    column-gap: 20px;
    row-gap: 30px;
  }
  &-tables {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
  .formWrapper {
    label {
      font-family: $avertaSemiBold;
    }
  }
  .form {
    margin-bottom: 15px;
    width: 300px;
    label {
      font-family: $avertaSemiBold;
    }

    input,
    select {
      border-radius: 4px;
      background-color: $light_grey;
      border: 1px solid $grey;
      padding: 0px 15px;
      width: 300px;
      font-size: $extrathin;
      margin-right: 10px;
      // height: fit-content;

      &:focus {
        outline-offset: 0px;
        outline: none;
        padding: 0px 15px;
        border: 1px solid blue;
      }
    }
    .showerror {
      border: 1px solid red;
      background: #ff434305;
      &:focus {
        border: 1px solid red;
        outline-offset: 0px;
        outline: none;
      }
    }
  }
  .displayMessage {
    background-color: lighten($defaultcolor, 30%);
    padding: 20px;
    border-radius: 4px;
    color: darken($defaultcolor, 30%);
    border: 1px solid $defaultcolor;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
// }
