.nepalmap-container {
  .nepalmap {
    position: relative;
    z-index: 0;

    .leaflet-container {
      width: 100%;
      height: 70vh;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
      position: relative;
    }

    &-legend {
      background: white;
      position: absolute;
      // bottom: 15px;
      top: 0px;
      right: 0px;
      z-index: 500;
      padding: 5px 5px;
      .clear {
        width: 100%;
        font-style: italic;
        text-decoration: underline;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        p {
          cursor: pointer;
        }
      }
      &-item {
        display: flex;
        align-items: center;

        %canvas {
          width: 30px;
          height: 30px;
          margin-right: 5px;
        }

        &-index1 {
          //red
          background: #cb2b3e;
          @extend %canvas;
        }

        &-index2 {
          //orange
          background: #cb8427;
          @extend %canvas;
        }
        &-index3 {
          // gold
          background: #ffd326;
          @extend %canvas;
        }
        &-index4 {
          //yellow
          background: #cac428;
          @extend %canvas;
        }
        &-index5 {
          //green
          background: #2aad27;
          @extend %canvas;
        }
        &-index6 {
          //blue
          background: #313cd1;
          @extend %canvas;
        }
      }
    }
  }
}

.map-container {
}
.card {
  background: white;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
  // display: flex;
  // flex-direction: column;
  padding: 30px 40px;
  // justify-content: center;
  // align-items: center;
  // transition: box-shadow 0.4s;
}
