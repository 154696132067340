.moduleStatus-container {
  .moduleStatus {
    // background: white;
    // border-radius: 4px;
    // box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
    // padding: 20px;

    table,
    tr,
    th,
    td {
      padding: 20px 2px;
      border: 1px solid rgb(190, 190, 190);
      text-align: center;
      //   border: 1px solid black;
      border-collapse: collapse;
      font-size: 14px;
      .zeropadding {
        padding: 0;
      }
    }

    .table-header {
      th {
        background-color: #fcd5b4;
      }
    }
    .table-label {
      background-color: #fef9cd;
    }
    th {
      //   background-color: $grey200;
    }
  }
}
