.tabAuth {
  display: flex;
  list-style-type: none;
  align-self: flex-end;
  flex: 1;

  border-bottom: 1px solid $light_black;
  .tabAuth-list-item {
    cursor: pointer;
    div {
      padding: 8px 12px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      span.title {
        color: $defaulttextcolor;
        padding: 0px 10px;
        transition: color 0.1s;
      }
    }

    &:hover {
      background: $defaultborder;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &.active {
      div {
        // border-bottom: 3px solid $defaultcolor;
        color: $black;
        background: $defaultcolor;
        color: $white;
      }
    }
  }
}
